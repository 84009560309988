@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply 
    overflow-x-hidden
    overflow-auto
    font-secondary 
    bg-white;
  }
  .h1 {
    @apply 
    text-[54px] 
    lg:text-[108px] 
    font-primary 
    font-bold 
    capitalize 
    leading-[120%] 
    tracking-[-0.05em] 
    mb-2;
  }
  .section {
    @apply 
    h-screen 
    w-screen;
  }
  .btn {
    @apply 
    py-[18px] 
    px-[50px] 
    h-[66px] 
    flex 
    items-center 
    justify-center 
    text-base 
    uppercase 
    bg-black
    font-secondary 
    font-semibold 
    text-white;
  }
}
